import React from "react";
import useTranslations from "../../../components/useTranslations";
import Footer from "../../../components/Footer/Footer";
import CompanyLeftNav from "../../../components/TwoColumnLeftNav/CompanyLeftNav";

const History = () => {
	const { history } = useTranslations();

	return (
		<>
			<article className="company-info two-column-left-nav">
				<CompanyLeftNav />
				<section className="main-content">
					<div className="img-box company-hero flex align-center">
						<h1>{history}</h1>
					</div>
					<h2 className={"large bold"}>{history}</h2>
					<div className="content-container gray">
						<div className="inner">
							<ul className={"company-info-flex"}>
								<li className="flex">
									<p className={"info-title"}>昭和23年 4月</p>
									<p className={"info-body"}>
										佐藤三千太郎が硝子温度計専門メーカーとして創業
									</p>
								</li>
								<li className="flex">
									<p className={"info-title"}>昭和38年 8月</p>
									<p className={"info-body"}>金属温度計、気象計器製造開始</p>
								</li>
								<li className="flex">
									<p className={"info-title"}>昭和45年 4月</p>
									<p className={"info-body"}>
										板橋工場新装、東京都板橋区大谷口に配送センター開設
									</p>
								</li>
								<li className="flex">
									<p className={"info-title"}>昭和46年 9月</p>
									<p className={"info-body"}>大阪支店開設</p>
								</li>
								<li className="flex">
									<p className={"info-title"}>昭和53年 2月</p>
									<p className={"info-body"}>福岡営業所開設</p>
								</li>
								<li className="flex">
									<p className={"info-title"}>昭和54年 5月</p>
									<p className={"info-body"}>北陸営業所開設</p>
								</li>
								<li className="flex">
									<p className={"info-title"}>昭和55年 4月</p>
									<p className={"info-body"}>
										電気計測器、デジタル計器分野へ進出
									</p>
								</li>
								<li className="flex">
									<p className={"info-title"}>昭和58年 9月</p>
									<p className={"info-body"}>名古屋営業所開設</p>
								</li>
								<li className="flex">
									<p className={"info-title"}>昭和60年 4月</p>
									<p className={"info-body"}>札幌営業所開設</p>
								</li>
								<li className="flex">
									<p className={"info-title"}>昭和60年 8月</p>
									<p className={"info-body"}>
										配送センターを埼玉県朝霞市に新装移設
									</p>
								</li>
								<li className="flex">
									<p className={"info-title"}>昭和61年 4月</p>
									<p className={"info-body"}>技術研究所設立</p>
								</li>
								<li className="flex">
									<p className={"info-title"}>昭和63年 12月</p>
									<p className={"info-body"}>仙台営業所開設</p>
								</li>
								<li className="flex">
									<p className={"info-title"}>平成 4年 1月</p>
									<p className={"info-body"}>宮城工場開設</p>
								</li>
								<li className="flex">
									<p className={"info-title"}>平成 6年 4月</p>
									<p className={"info-body"}>大阪支店新装移設</p>
								</li>
								<li className="flex">
									<p className={"info-title"}>平成 9年 4月</p>
									<p className={"info-body"}>名古屋営業所新装移設</p>
								</li>
								<li className="flex">
									<p className={"info-title"}>平成13年 10月</p>
									<p className={"info-body"}>宮城工場二期工事完成</p>
								</li>
								<li className="flex">
									<p className={"info-title"}>平成18年 5月</p>
									<p className={"info-body"}>
										製品の備品･消耗品を主体にインターネットによる通信販売を開始
									</p>
								</li>
								<li className="flex">
									<p className={"info-title"}>平成19年 10月</p>
									<p className={"info-body"}>富山営業所開設 </p>
								</li>
								<li className="flex">
									<p className={"info-title"}>平成19年 11月</p>
									<p className={"info-body"}>
										東京営業所を拡大に伴い､本社営業部と東京営業所を開設
									</p>
								</li>
								<li className="flex">
									<p className={"info-title"}>平成25年 5月</p>
									<p className={"info-body"}>福岡営業所移転</p>
								</li>
								<li className="flex">
									<p className={"info-title"}>平成29年 12月</p>
									<p className={"info-body"}>
										本社移転､東京営業所を本社営業部へ統合
									</p>
								</li>
							</ul>
						</div>
					</div>
				</section>
			</article>
			<Footer />
		</>
	);
};

export default History;
